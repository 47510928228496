import ModalBaggage from "app/desktop/ui/components/modal-baggage/modal.baggage";
import {TxtFieldSmall} from "app/desktop/ui/components/txt-field-small/txt.field.small";
import {BlueButton} from "app/desktop/ui/components/buttons/blue-button/blue.button";
import React from "react";
import {inject, observer} from "mobx-react";
import {isEmailValid, isPhoneNumberValid} from "app/services/validator";
import {Preloader} from "app/desktop/ui/components/preloader/preloader";
import {NotAvailableSeats} from "app/desktop/ui/components/dialogs/dlg-seat-selection/dlg.seat.selection";

@inject("baggageStore", "checkInUIStore", "pnrStore", "checkInPopup", "seatsStore", "dashboardUIStore") @observer
class ModalSendMessage extends React.Component {
    render() {

        const s = this.props.seatsStore;

        const greenMark = this.props.type === "sent" &&
            <div className={"boarding-pass-done-mark"}/>;

        const handleClickButton = () => {
            if (this.props.type!== "sent") {
                if (this.props.type === "phone") {
                    return isPhoneNumberValid(this.props.inputValue) ? this.props.performSendMessage() : s.showErrorMessage(true, "the phone number is not valid");
                }
                if (this.props.type === "email") {
                    return isEmailValid(this.props.inputValue) ? this.props.performSendMessage() : s.showErrorMessage(true, "the email is not valid");
                }

            } else {
                this.props.handleSentMessage();
            }
        }

        const autoComplete = (this.props.type === "phone") ? "tel-national" : "email";


        const mainSection = this.props.type === "sent" ?
            <a onClick={()=> this.props.performSendMessage(this.props.type)}>{this.props.sendAgain}</a> :
            <div className={"payment-page"}>
                <div className={"payment-form"}>
                    <div className={"row"}>
                        <div className={"col col-2"}>
                            <label>{this.props.label}</label>
                            <TxtFieldSmall
                                value={this.props.inputValue}
                                onChange={(e) => {
                                    this.props.updateValue(e.target.value);
                                }}
                                autoComplete={autoComplete}
                                isEng={true}
                                placeholder={""}
                                maxLength={this.props.type === "email"? 30 : 13}
                            />
                        </div>
                    </div>
                </div>
                <div className={"error-message-wrapper"}>
                    {/*TODO: add error handler for recipe usage*/}
                    <NotAvailableSeats text={s.errText}/>
                </div>
            </div>


        return (
            <ModalBaggage boardingPass isOpen={this.props.isOpen}
                          onClose={() => this.props.closePopup()}>
                {this.props.boardingPassLoading ? <Preloader/> :
                    <div className={"bp-form-main-div"}>
                        {greenMark}
                        <div className={"bp-form-text-block"}>
                            <p>{this.props.title}</p>
                        </div>
                        <div className={"bp-form-second-div"}>
                            {mainSection}
                        </div>
                        <div className={"bp-form-bottom-button"}>
                            <BlueButton
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleClickButton();
                                }}>
                                <div className={"bp-form-bottom-button-inner"}>{this.props.btnText}</div>
                            </BlueButton>
                        </div>
                        <div/>
                    </div>
                }
            </ModalBaggage>

        )
    }
}

ModalSendMessage.defaultProps = {
    sendAgain: "לא קבלתי, שילחו אלי את הכרטיסים שוב"
}

export {ModalSendMessage};