import { inject, observer } from 'mobx-react';
import React from 'react';


@inject("pnrStore") @observer
class BaggageWarning extends React.Component {
    render () {
        return null;
    }
}

export default BaggageWarning