import React from 'react'
import 'app/desktop/ui/components/txt-field/txt.field.scss'
import {observer} from "mobx-react";
import classnames from 'classnames';

const HelpIcon = (props) => {
    return (
        <div className={"field-tooltip"}>
            <div className={"icon-help"}/>
            <div className={"tooltip"}><div className={"text"}>{props.tooltip}</div> </div>
        </div>
    )
}



@observer
class TxtField extends React.Component {
    render() {
        const css = classnames("txt-field",
            {"error" : this.props.isError},
            {"disabled" : this.props.disabled},
            {"with-tooltip" : !!this.props.tooltip}
        );

        const tooltip = (this.props.tooltip) ? <HelpIcon tooltip={this.props.tooltip}/> : "";
        const otherProps  = {}
        if (this.props.autoComplete) {
            otherProps.autoComplete = this.props.autoComplete;
        }

        return (
            <div className={css}>
                <input type={this.props.type}
                        value={this.props.value}
                       placeholder={this.props.placeholder}
                       onChange={this.props.onChange}
                       disabled={this.props.disabled}
                       maxLength={this.props.maxLength}
                       name={this.props.name}
                       {...otherProps}
                />
                {tooltip}
            </div>
        )
    }
}

TxtField.defaultProps = {
    placeholder : "",
    value : "",
    isError : false,
    disabled : false,
    maxLength : 524288,
    tooltip : null,
    type : "text",
    name : "",
    autoComplete : "",
    onChange : () => {}
}

export {TxtField}