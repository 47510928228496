import React from 'react'
import "app/desktop/ui/components/login-form/login.form.scss"
import {TxtField} from "app/desktop/ui/components/txt-field/txt.field";
import {MainButton} from "app/desktop/ui/components/buttons/main-button/main.button";
import {inject, observer} from "mobx-react";
import {AuthStoreErrors} from "app/stores/auth.store";
import {CancelButton} from "app/desktop/ui/components/buttons/cancel-button/cancel.button";
import ReCAPTCHA from "react-google-recaptcha";

const Loading = () => {
    return (
        <div className={"preloader"}/>
    )
}

@inject("authStore", "pnrStore") @observer
class ErrorMessage extends React.Component {
    
    render() {
        const s = this.props.authStore;

        if (s.errorStatus === AuthStoreErrors.OTHER) {
            return (
                <div className={"error"}>{s.errorMessage}</div>
            )

        }

        if (s.errorStatus === AuthStoreErrors.SMS_VERIFICATION_ERROR) {
            return (
                <div className={"error"}>קוד לא תקין</div>
            )

        }

        return (
            <div className={"error"}>אחד מהפרטים חסר או לא תקין</div>
        )
    }
}


@inject("authStore", "pnrStore") @observer
class LoginInitial extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            isRecaptchaValid: process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY ? false : true
        };
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.props.authStore.isLoading) {
            console.log("already loading...")
        } else {
            this.props.authStore.performInitialAuth();
        }

    }

    render() {
        // const url = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAMAAAANIilAAAABLFBMVEUAAACqqqrS0tLa2trb29vZ2dna2trZ2dnY2NjW1tbU1NTS0tLNzc3Nzc3JycnMzMzFxcXAwMC7u7u3t7e3t7e0tLS0tLSxsbGsrKynp6eoqKinp6eioqKfn5+enp6fn5+enp6ZmZmXl5eVlZWSkpKUlJSQkJCKioqHh4eBgYGDg4OAgIB/f399fX17e3t5eXl1dXV0dHRxcXFycnJubm5vb29tbW1sbGxra2tpaWlnZ2dlZWVkZGRkZGRjY2NjY2NiYmJgYGBhYWFfX19eXl5dXV1eXl5bW1tbW1tZWVlXV1dVVVVVVVVVVVVTU1NTU1NTU1NRUVFRUVFPT09PT09OTk5OTk5NTU1MTExLS0tLS0tKSkpISEhISEhISEhHR0dHR0dHR0dGRkZGRkZwjYjjAAAAY3RSTlMAAxE3OUlSXmJjZGVmZ2hoam1wcXJzdHZ4ent8foCBgoOFh4mKio6SlZqam52foKWoqaussLGytLW4ub2/wMHCw8TFxsjLy8/Q09Xa29zd3uDj5ufp6uvt7vHz9vf5+vv8/f5AsI+sAAABPElEQVR42pzLNwEAQQwEsXU2f8IfEdyol14ePXtgOlw/ywXSvluL1LtzoZR8MVfwHGqeW8PzaJe72a2nRQWiAArDt8e2bZtzWE223Xr/Z8gcbuT677/hVn/ihfNfOzP2AUFWvA4OfM+DHRx4LsOBL8GBRTmeJMVTcQleEgIPpPgYEvwGEOMvCZ6IkOPRsASfghzvohGPWSnwSz1euzZFQYE9FTx39hNCOUK8glJxdwVS4BtII8erLsiLifu6eO70OyiDadvT3rjO2J4+enVDlvf9eEZ3YhxYkjIY/r9YJJlVuykJjJtvN0inpNDwks7nwwmKpfezKv0fJ7OU6/ZmogAjhqtllkV/5894tz3aju1miOcf9VPFW9CvC/HUln6DPki4Dq5cR2a+w3pulHQTKOmgUNQ1oqxTRlF3EACGKAg9XBef6QAAAABJRU5ErkJggg==";
        // const base64Image = url.split(",")[1];
        // const decodedImage = atob(base64Image);
        // const imageBytes = new Uint8Array(decodedImage.length);
        // for (let i = 0; i < decodedImage.length; i++) {
        //     imageBytes[i] = decodedImage.charCodeAt(i);
        // }
        // const blob = new Blob([imageBytes], { type: "image/png" });
        // const blobUrl = URL.createObjectURL(blob);
        // const img = new Image();
        // img.src = blobUrl;
        // console.log(img)
        // console.log("__________________________________________________________________")

        const s = this.props.authStore;
        const status = (s.isLoading) ? <Loading/> : (s.errorStatus !== AuthStoreErrors.NONE) ? <ErrorMessage/> : "";
        const { isRecaptchaValid } = this.state;
        const skipCaptcha = process.env.REACT_APP_SKIP_RECAPTCHA === "1";
        const isRecaptchaEnabled = Boolean(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY) && !skipCaptcha;
        const btnDisabled = isRecaptchaEnabled ? !isRecaptchaValid : false;

        return (
            <div>
                <div className={"line-1"}>ברוכים הבאים</div>
                <div className={"line-2"}>לאיזור האישי שלכם</div>
                <p><strong className={"line-3-title"}>לביצוע פעולות באופן עצמאי ושדרוג החופשה שלכם!</strong><br/>הוספת כבודה/הושבה והזמנת שירותים נוספים.
                    כאן ניתן לבצע צ'ק אין אונליין לטיסות<br/> Blue Bird Airways
                </p>

                <form action={"#"} onSubmit={this.handleSubmit}>
                    <div className={"rows"}>
                        <div className={"row"}>
                            <TxtField placeholder={"מספר הזמנה"}
                                      value={s.pnr}
                                      isError={s.dataError}
                                      disabled={s.isLoading}
                                      maxLength={11}
                                      tooltip = {"מספר ההזמנה מורכב מ-8 תווים (מתחיל בספרה 5 או 305)"}
                                      autoComplete={"off"}
                                      onChange = {(e) => {
                                          s.setPNR(e.target.value);
                                      }}/>


                        </div>

                        <div className={"row"}>
                            <TxtField placeholder={"מספר טלפון"}
                                      type="number"
                                      value={s.phone}
                                      maxLength={32}
                                      isError={s.dataError}
                                      disabled={s.isLoading}
                                      autoComplete={"tel-national"}
                                      onChange = {(e) => {
                                          s.setPhone(e.target.value);
                                      }}/>

                        </div>

                        <div className={"row"}>
                            <div className={"activity-container"}>
                                {status}
                            </div>
                        </div>
                        
                        {isRecaptchaEnabled && (
                            <div className={"recaptcha-container"}>
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                                    size="normal"
                                    hl="iw"
                                    onChange={(token) => {
                                        this.setState({ isRecaptchaValid: true })
                                    }}
                                />
                            </div>
                        )}

                        <div className={"row"}>
                            <div className={"button-placeholder"}>
                                <MainButton disabled={btnDisabled} onClick={() => {s.performInitialAuth();}}>כניסה</MainButton>
                            </div>
                        </div>
                    </div>
                    <input type={"submit"} style={{"visibility":"hidden", "display":"none"}}/>
                </form>
            </div>
        )
    }
}

@inject("authStore", "pnrStore") @observer
class SmsVerification extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.props.authStore.performSmsVerification();
    }

    render() {
        const s = this.props.authStore;
        const status = (s.isLoading) ? <Loading/> : (s.errorStatus !== AuthStoreErrors.NONE) ? <ErrorMessage/> : "";
        const timeText = (s.remainingTime > 0) ? <span>SMS עם הקוד יתקבל תוך {s.remainingTimeText}</span> : <a href={"#"} onClick={(e) => {
            e.preventDefault();
            s.performInitialAuth();}
        }><strong>שלח לי קוד חדש</strong></a>;
        return (
            <div>
                <div className={"line-2"}>הזנת קוד אימות</div>
                <p>קוד אימות נשלח לטלפון הנייד {s.normalizedPhone} <br/>
                    {timeText}

                </p>


                <form onSubmit={this.handleSubmit}>
                    <div className={"rows"}>
                        <div className={"row"}>
                            <TxtField placeholder={"קוד אימות"}
                                      value={s.smsCode}
                                      isError={s.dataError}
                                      disabled={s.isLoading}
                                      maxLength={5}
                                      autoComplete="one-time-code"
                                      type="text"
                                      onChange = {(e) => {
                                          s.setSmsCode(e.target.value);
                                      }}/>
                        </div>

                        <div className={"row"}>
                            <div className={"activity-container"}>
                                {status}
                            </div>
                        </div>

                        <div className={"row"}>
                            <div className={"buttons"}>
                                <CancelButton onClick={() => {s.cancelSmsVerification();}}>ביטול</CancelButton>
                                <MainButton disabled={s.remainingTime <=0} onClick={() => {s.performSmsVerification();}}>סיום</MainButton>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

@inject("authStore", "pnrStore") @observer
class LoginForm extends React.Component {
    render() {
        const s = this.props.authStore;
//        const status = (s.isLoading) ? <Loading/> : (s.errorStatus !== AuthStoreErrors.NONE) ? <ErrorMessage/> : "";
        const body = (!s.smsSent) ? <LoginInitial/> : <SmsVerification/>
        return (
            <div className={"cs-login-form"}>
                <div className={"header-image"}/>
                <div className={"form-body"}>
                    <div className={"form-header"}>
                        {body}
                    </div>
                </div>
            </div>
        )
    }
}

export {LoginForm}