import React from 'react';
import InfoIcon from "app/desktop/ui/components/info-icon/info.icon";
import "app/desktop/ui/components/important-info/important-info.scss";

const ImportantInfo = ({ infoText, showWarning }) => {
    return (
        <div className={"imp-info-container"}>
            <InfoIcon red/>
            <div className={"imp-info-text"}>
                {infoText}
            </div>
        </div>
    )
}

export default ImportantInfo;