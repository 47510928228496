import {inject, observer} from "mobx-react";
import React from "react";
import {PaymentButton} from "app/desktop/ui/components/buttons/main-button/main.button";

@inject('paymentStore', 'dashboardUIStore', 'extraServicesStore', 'baggageStore', 'seatsStore', 'isWebCheckIn', 'checkInUIStore') @observer
class InitiatePayment extends React.Component {

    render() {

        const seats = this.props.seatsStore;
        const uis = this.props.dashboardUIStore;
        const pay = this.props.paymentStore;
        const bg = this.props.baggageStore;
        const es = this.props.extraServicesStore;
        const {isWebCheckIn} = this.props;

        const css = !isWebCheckIn && "col-2";

        const openWarningPopup = () => {
            if (isWebCheckIn) {
                uis.ticketCheckIn.openPopup();
            }
        }

        const isValid = (pay.isFormValid && (bg.totalBaggagePrice > 0 || seats.totalPrice > 0 || es.totalPrice > 0));

        return (
            <div className={css}>
                <PaymentButton
                    tooltip={pay.buttonTooltip}
                    disabled={!isValid || pay.disablePayment}
                    onClick={() => {
                        isWebCheckIn ? openWarningPopup() : pay.initiatePayment();
                    }}>תשלום באשראי</PaymentButton>
            </div>
        )
    }
}

export {InitiatePayment};